import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DialerLiveStats } from 'src/app/shared/models';

export const QueueLiveStatsActions = createActionGroup({
  source: 'QueueLiveStats',
  events: {
    'Load QueueLiveStats': props<{
      queueId: string;
      agencyId: string;
    }>(),
    'Load QueueLiveStats Success': props<{
      queueLiveStat: DialerLiveStats;
    }>(),
    'Load QueueLiveStats Failure': props<{ error: any }>(),
  },
});
