import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DialerLiveStats } from 'src/app/shared/models';

export const CampaignLiveStatsActions = createActionGroup({
  source: 'CampaignLiveStats',
  events: {
    'Load CampaignLiveStats': props<{
      campaignId: string;
      agencyId: string;
    }>(),
    'Load CampaignLiveStats Success': props<{
      campaignLiveStat: DialerLiveStats;
    }>(),
    'Load CampaignLiveStats Failure': props<{ error: any }>(),
  },
});
