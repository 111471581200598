import { createFeature, createReducer, on } from '@ngrx/store';
import { QueueLiveStatsActions } from './queue-live-stats.actions';
import { DialerLiveStats } from 'src/app/shared/models';

export const queueLiveStatsFeatureKey = 'queueLiveStats';

export interface QueueLiveStatsState {
  queueLiveStat: DialerLiveStats | null;
  queueLiveStats: DialerLiveStats[];
  loading: boolean;
  error: any;
}

export const initialState: QueueLiveStatsState = {
  queueLiveStat: null,
  queueLiveStats: [],
  loading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    QueueLiveStatsActions.loadQueueLiveStats,
    (state): QueueLiveStatsState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    QueueLiveStatsActions.loadQueueLiveStatsSuccess,
    (state, { queueLiveStat }): QueueLiveStatsState => ({
      ...state,
      queueLiveStats: [queueLiveStat],
      queueLiveStat,
      loading: false,
      error: null,
    }),
  ),
  on(
    QueueLiveStatsActions.loadQueueLiveStatsFailure,
    (state, { error }): QueueLiveStatsState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
);

export const queueLiveStatsFeature = createFeature({
  name: queueLiveStatsFeatureKey,
  reducer,
});
