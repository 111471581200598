import { createFeature, createReducer, on } from '@ngrx/store';
import { CampaignLiveStatsActions } from './campaign-live-stats.actions';
import { DialerLiveStats } from 'src/app/shared/models';

export const campaignLiveStatsFeatureKey = 'campaignLiveStats';

export interface CampaignLiveStatsState {
  campaignLiveStat: DialerLiveStats | null;
  campaignLiveStats: DialerLiveStats[];
  loading: boolean;
  error: any;
}

export const initialState: CampaignLiveStatsState = {
  campaignLiveStat: null,
  campaignLiveStats: [],
  loading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    CampaignLiveStatsActions.loadCampaignLiveStats,
    (state): CampaignLiveStatsState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    CampaignLiveStatsActions.loadCampaignLiveStatsSuccess,
    (state, { campaignLiveStat }): CampaignLiveStatsState => ({
      ...state,
      campaignLiveStats: [...state.campaignLiveStats.filter((x) => campaignLiveStat.parentId !== x.parentId || campaignLiveStat.id !== x.id), campaignLiveStat],
      campaignLiveStat,
      loading: false,
      error: null,
    }),
  ),
  on(
    CampaignLiveStatsActions.loadCampaignLiveStatsFailure,
    (state, { error }): CampaignLiveStatsState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
);

export const campaignLiveStatsFeature = createFeature({
  name: campaignLiveStatsFeatureKey,
  reducer,
});
