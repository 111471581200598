import { createActionGroup, props } from '@ngrx/store';
import { DialerCampaignHopper } from 'src/app/shared/models';

export const CampaignHopperActions = createActionGroup({
  source: 'CampaignHopper',
  events: {
    'Load Campaign Hopper': props<{ queueId: string; agencyId: string }>(),
    'Load Campaign Hopper Success': props<{ queueId: string; agencyId: string; campaignHopper: DialerCampaignHopper }>(),
    'Load Campaign Hopper Failure': props<{ error: any }>(),
  },
});
