import { createFeature, createReducer, on } from '@ngrx/store';
import { CampaignHopperActions } from './campaign-hopper.actions';
import { DialerCampaignHopper } from 'src/app/shared/models';

export const campaignHopperFeatureKey = 'campaignHopper';

export interface CampaignHopperState {
  campaignHopper: DialerCampaignHopper | null;
  // campaignHoppers: { [key: string]: { [key: string]: DialerCampaignHopper } };
  campaignHoppers: DialerCampaignHopper[];
  loading: boolean;
  error: any;
}

export const initialState: CampaignHopperState = {
  campaignHopper: null,
  campaignHoppers: [],
  loading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    CampaignHopperActions.loadCampaignHopper,
    (state): CampaignHopperState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    CampaignHopperActions.loadCampaignHopperSuccess,
    // (state, { agencyId, queueId, campaignHopper }): CampaignHopperState => {
    //   if (!state.campaignHoppers[agencyId]) {
    //     state.campaignHoppers[agencyId] = {}
    //   }
    //   state.campaignHoppers[agencyId][queueId] = campaignHopper

    //   return state
    // }
    (state, { agencyId, queueId, campaignHopper }): CampaignHopperState => ({
      ...state,
      campaignHoppers: [...state.campaignHoppers.filter((x) => agencyId !== x.agencyId || queueId !== x.queueId), campaignHopper],
      loading: false,
      error: null,
    })
  ),
  on(
    CampaignHopperActions.loadCampaignHopperFailure,
    (state, { error }): CampaignHopperState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
);

export const campaignHopperFeature = createFeature({
  name: campaignHopperFeatureKey,
  reducer,
});
